import { getCookie } from "./general";
import { getItemCrypt } from './auth'
import { criptografar } from "./crypto";

export const uriList = (uri) => {
	const bank = getItemCrypt(TOKEN_BANK, true)

	switch (bank) {
		case 'inbursa':
			//-- Servidor
			_port = process.env.REACT_APP_PORTINBURSA || process.env.REACT_APP_PORT
			_root = (process.env.REACT_APP_ROOTINBURSA || process.env.REACT_APP_ROOT) + `${_port ? ':' + _port : ''}`

			//-- Servico auxiliar
			_portAux = (process.env.REACT_APP_PORTAUXINBURSA || process.env.REACT_APP_PORTAUX) || _port
			_rootAux = process.env.REACT_APP_ROOTAUXINBURSA ? process.env.REACT_APP_ROOTAUXINBURSA + `${_portAux ? ':' + _portAux : ''}` : _root

			//-- Microserviço
			_portMS = process.env.REACT_APP_PORTMSINBURSA || _port
			_rootMS = process.env.REACT_APP_ROOTMSINBURSA ? process.env.REACT_APP_ROOTMSINBURSA + `${_portMS ? ':' + _portMS : ''}` : _root

			//-- Websocket
			_portWs = process.env.REACT_APP_PORTWSINBURSA
			WsUrl = `${process.env.REACT_APP_WSURLINBURSA} + ${_portWs ? ':' + _portWs : ''}`
			break
		case 'cef':
			//-- Servidor
			_port = process.env.REACT_APP_PORTCEF || process.env.REACT_APP_PORT
			_root = (process.env.REACT_APP_ROOTCEF || process.env.REACT_APP_ROOT) + `${_port ? ':' + _port : ''}`

			//-- Servico auxiliar
			_portAux = (process.env.REACT_APP_PORTAUXCEF || process.env.REACT_APP_PORTAUX) || _port
			_rootAux = process.env.REACT_APP_ROOTAUXCEF ? process.env.REACT_APP_ROOTAUXCEF + `${_portAux ? ':' + _portAux : ''}` : _root

			//-- Microserviço
			_portMS = process.env.REACT_APP_PORTMSCEF || _port
			_rootMS = process.env.REACT_APP_ROOTMSCEF ? process.env.REACT_APP_ROOTMSCEF + `${_portMS ? ':' + _portMS : ''}` : _root

			//-- Websocket
			_portWs = process.env.REACT_APP_PORTWSCEF
			WsUrl = `${process.env.REACT_APP_WSURLCEF} + ${_portWs ? ':' + _portWs : ''}`
			break
		default:
			//-- Servidor
			_port = process.env.REACT_APP_PORT
			_root = process.env.REACT_APP_ROOT + `${_port ? ':' + _port : ''}`

			//-- Servico auxiliar
			_portAux = process.env.REACT_APP_PORTAUX || _port
			_rootAux = process.env.REACT_APP_ROOTAUX ? process.env.REACT_APP_ROOTAUX + `${_portAux ? ':' + _portAux : ''}` : _root

			//-- Microserviço
			_portMS = process.env.REACT_APP_PORTMS || _port
			_rootMS = process.env.REACT_APP_ROOTMS ? process.env.REACT_APP_ROOTMS + `${_portMS ? ':' + _portMS : ''}` : _root

			//-- Websocket
			_portWs = process.env.REACT_APP_PORTWS
			WsUrl = `${process.env.REACT_APP_WSURL} + ${_portWs ? ':' + _portWs : ''}`
			break
	}

	// //-- Obtendo rotas padrão
	// if (!_root) {
	// 	//-- Servidor
	// 	_port = process.env.REACT_APP_PORT
	// 	_root = process.env.REACT_APP_ROOT + `${_port ? ':' + _port : ''}`

	// 	//-- Servico auxiliar
	// 	_portAux = process.env.REACT_APP_PORTAUX || _port
	// 	_rootAux = process.env.REACT_APP_ROOTAUX ? process.env.REACT_APP_ROOTAUX + `${_portAux ? ':' + _portAux : ''}` : _root

	// 	//-- Microserviço
	// 	_portMS = process.env.REACT_APP_PORTMS || _port
	// 	_rootMS = process.env.REACT_APP_ROOTMS ? process.env.REACT_APP_ROOTMS + `${_portMS ? ':' + _portMS : ''}` : _root

	// 	//-- Websocket
	// 	_portWs = process.env.REACT_APP_PORTWS
	// 	WsUrl = `${process.env.REACT_APP_WSURL} + ${_portWs ? ':' + _portWs : ''}`
	// }
	console.log("config.uriList", { bank, uri, _root, _port, _rootAux, _portAux, _rootMS, _portMS, WsUrl })

	const _result = _uriList(uri)

	return _result
}

//-- Servidor
let _port = process.env.REACT_APP_PORT
let _root = process.env.REACT_APP_ROOT + `${_port ? ':' + _port : ''}`

//-- Servico auxiliar
let _portAux = process.env.REACT_APP_PORTAUX || _port
let _rootAux = process.env.REACT_APP_ROOTAUX ? process.env.REACT_APP_ROOTAUX + `${_portAux ? ':' + _portAux : ''}` : _root

//-- Microserviço
let _portMS = process.env.REACT_APP_PORTMS || _port
let _rootMS = process.env.REACT_APP_ROOTMS ? process.env.REACT_APP_ROOTMS + `${_portMS ? ':' + _portMS : ''}` : _root

//-- Websocket
let _portWs = process.env.REACT_APP_PORTWS
let WsUrl = `${process.env.REACT_APP_WSURL} + ${_portWs ? ':' + _portWs : ''}`

let baseroot = process.env.REACT_APP_BASE_ROOT

const _uriList = uri => ({
	'simulador': `${_root}${baseroot}local/simulacao`,
	'convenios': `${_root}/convenios`,
	'empresas': `${_root}/empresas`,
	'conveniosFilter': `${_root}/conveniosFilter`,
	'conveniosConsignado': `${_root}/conveniosConsignado`,
	'listarConvenios': `${_root}/convenios/listarConvenios`,
	'listarConveniosCartao': `${_root}/convenios/listarConveniosCartao`,
	'emails': `${_root}/emails`,
	'resultado': `${_root}/propostas/index`,
	'documentos': `${_root}/propostaanexos/upload`,
	'submit': `${_root}${baseroot}local/enviarProposta`,
	'acompanharProposta': `${_root}/propostas/acompanharProposta`,
	'acompanhar': `${_root}/propostas/acompanhar`,
	'acompanharccb': `${_root}/propostas/acompanharCcb`,
	'buscar': `${_root}/propostas/buscarProposta`,
	'alterarproposta': `${_root}/propostas/alterarProposta`,
	'novaFormalizacao': `${_root}/propostas/novaFormalizacao`,
	'antifraude': `${_root}/propostas/antifraude`,
	'listarPropostas': `${_root}/propostas/listarPropostas`,
	'listarAntifraude': `${_root}/antifraude/listar`,
	'pessoa': `${_root}/pessoas/buscarPessoa`,
	'prazos': `${_root}/tabelas/prazos`,
	'tabelas': `${_root}/tabelas`,
	'listarTabelas': `${_root}/tabelas/listarTabelas`,
	'listarConvenios': `${_root}/convenios/listarConvenios`,
	'coeficientes': `${_root}/coeficientes`,
	'pessoas': `${_root}/pessoas`,
	'listarPessoas': `${_root}/pessoas/listar`,
	'propostacmp': `${_root}/propostacmp`,
	'fluxoProposta': `${_root}/propostas/obterFluxo`,

	'docs-show': `${_root}/propostaanexos/show`,
	'docs-remove': `${_root}/propostaanexos/remove`,
	'docs-download': `${_root}/propostaanexos/download`,
	'docs-upload-nuvem': `${_root}/propostaanexos/uploadAnexosNuvem`,
	'integracoes': `${_root}/integracoes`,
	'copiar1PaginaCcb': `${_root}/propostaanexos/obterPrimeiraPaginaCcb`,
	'obterUrlCcb': `${_root}/propostaanexos/obterUrlCcb`,
	'gerarDossieExterno': `${_root}/propostaanexos/gerarDossieExterno`,

	//-- Checklist
	'checkpoint': `${_root}/checkpoint`,
	'checklist': `${_root}/checklist`,
	'checklistitem': `${_root}/checklistitem`,
	'checklistItens': `${_root}/checklistitem/index`,
	'getChecklist': `${_root}/propostacl/getChecklist`,
	'testarChecklist': `${_root}/propostacl/testar`,
	'gravarChecklist': `${_root}/propostacl/gravar`,

	//-- URIs fila de propostas 
	'enviarParaAssinatura': `${_root}/propostas/enviarParaAssinatura`,
	'reenviarParaAssinatura': `${_root}/propostas/reenviarParaAssinatura`,
	'informarAssinatura': `${_root}/propostas/informarAssinatura`,
	'voltarStatusProposta': `${_root}/propostas/voltarStatus`,

	'pendenciar': `${_root}/propostas/pendenciar`,
	'despendenciar': `${_root}/propostas/solucionarPendencia`,
	'pendencias': `${_root}/pendencias/index`,
	'analisarPendencia': `${_root}/pendencias/analisar`,
	'pendenciashst': `${_root}/pendencias/historico`,
	'retornar': `${_root}/pendencias/retornar`,

	'bloquear': `${_root}/propostas/bloquear`,
	'desbloquear': `${_root}/propostas/desbloquear`,
	'averbar': `${_root}/propostas/averbar`,
	'solicitarAverbacao': `${_rootMS}/propostas/solicitarAverbacao`,
	'enviarParaBanco': `${_rootMS}/propostas/enviarParaBanco`,
	'consultarStatusBanco': `${_root}/propostas/consultarStatusBanco`,
	'informarPagamento': `${_root}/propostas/informarPagamento`,
	'cancelar': `${_root}/propostas/cancelar`,
	'inutilizar': `${_root}/propostas/inutilizar`,
	'log': `${_root}/propostas/log`,
	'opcoesProposta': `${_root}/propostas/opcoes`,
	'confirmarProposta': `${_root}/propostas/confirmarProposta`,
	'enviarDossie': `${_root}/propostas/enviarDossie`,
	'reenviarDossie': `${_root}/propostas/reenviarDossie`,
	'posicaoProposta': `${_root}/propostas/posicaoProposta`,
	'reenviarParaBanco': `${_root}/propostas/reenviarParaBanco`,
	'alteracaoProposta': `${_root}/propostas/alteracaoProposta`,
	'consultarRestricoes': `${_root}/propostas/consultarRestricoes`,
	'enviarParaBancoCadastro': `${_root}/propostas/enviarParaBancoCadastro`,
	'reenviarParaBancoCadastro': `${_root}/propostas/reenviarParaBancoCadastro`,
	'gravarEtapas': `${_root}/propostas/gravarEtapas`,
	'enviarparaassinaturawhats': `${_root}/propostas/enviarProposta`,

	//-- URIs de administração
	'emailCliente': `${_root}/propostas/enviarProposta`,
	'login': `${_root}${baseroot}local/login`,
	'getUserByToken': `${_root}${baseroot}local/getUserByToken`,
	'gravarUsuario': `${_root}${baseroot}local/register`,
	'habilitarUsuario': `${_root}${baseroot}local/habilitar`,
	'usuarios': `${_root}${baseroot}local/usuarios`,
	'buscarUsuario': `${_root}${baseroot}local/buscarUsuario`,
	'buscaraclusuario': `${_root}${baseroot}local/buscaraclusuario`,
	'gravaraclusuario': `${_root}${baseroot}local/gravaraclusuario`,
	'tedreapresentada': `${_root}/propostas/tedreapresentada`,

	//-- URIs antifraude
	'checarAntiFraude': `${_root}/propostaconf/checarantifraude`,
	'consultarAntiFraude': `${_root}/propostaconf/consultarantifraude`,
	'conformidadeshow': `${_root}/propostaconf/show`,
	'analiseAutomatica': `${_root}/propostaconf/analiseautomaticaex`,
	'checarBloqueio': `${_root}/propostaconf/checarbloqueioex`,
	'aprovarConformidade': `${_root}/propostaconf/aprovarconformidadeex`,
	'liberarPropostaAdm': `${_root}/propostaconf/liberarPropostaAdm`,
	'checarAnaliseRealizada': `${_root}/propostaconf/checaranaliserealizada`,
	'aprovarAnalise': `${_root}/propostaconf/aprovarAnalise`,
	'checarCredLink': `${_root}/propostaconf/checarcredlink`,
	'gerarRelatorioAuditoria': `${_root}/propostas/gerarrelatorioauditoria`,
	'checarAnaliseOCR': `${_root}/propostaconf/checarOcr`,
	'consultarAnaliseOCR': `${_root}/propostaconf/consultarOcr`,

	//-- URIs outras
	'emailResponsvel': `${_root}/propostas/emailResponsavel`,
	'enviarEmail': `${_root}/email/enviarEmail`,
	'enviarCodigo': `${_root}/propostas/enviarCodigo`,
	'validarCodigo': `${_root}/propostas/validarCodigo`,
	'checarCodigo': `${_root}/propostas/checarCodigo`,
	'recuperarSenha': `${_root}${baseroot}local/recuperarSenha`,
	'novaSenha': `${_root}${baseroot}local/novaSenha`,
	'excluirlocks': `${_root}${baseroot}local/excluirLocks`,
	'gerarCcb': `${_root}/propostaccb/gerarCcb`,
	'consultarMargem': `${_root}/propostas/consultarmargem`,
	'documentos-geral': `${_root}/documentos`,
	'documentos-list': `${_root}/documentos/lista`,
	'documentos-remove': `${_root}/documentos`,
	'uploadLogo': `${_root}/usuarios/uploadlogo`,
	'dropLogo': `${_root}/usuarios/droplogo`,
	'checarTelefone': `${_root}/propostas/checartelefone`,
	'relatorioPortRefin': `${_root}/relatorios/PropostasPortRefin`,
	'obterDadosCartao': `${_root}/uteis/obterDadosCartao`,

	'observacoes': `${_root}/observacoes`,
	'parametros': `${_root}/parametros`,
	'listarObservacoes': `${_root}/observacoes/listar`,
	'listarAgentes': `${_root}/pessoas/listarAgentes`,
	'listarAgentesMst': `${_root}/pessoas/listarAgentesMst`,
	'getTabelasCPessoal': `${_root}/tabelas/getTabelasCPessoal`,
	'getTabelasRetencao': `${_root}/tabelas/getTabelasRetencao`,

	//-- Banco
	'consultarCPFBanco': `${_root}/propostas/consultarCPFBanco`,

	//-- Portabilidade
	'solicitarAutorizacaoPortabilidade': `${_root}/propostas/solicitarAutorizacaoPortabilidade`,
	'autorizarPortabilidade': `${_root}/propostas/autorizarPortabilidade`,
	'simularPortabilidade': `${_root}/propostas/simularPortabilidade`,
	'buscarContratosPort': `${_root}/propostas/buscarContratosPort`,
	'solicitarSaldoDevedor': `${_rootMS}/propostas/solicitarSaldoDevedor`,
	'consultarSolicitacaoSaldo': `${_rootMS}/propostas/consultarSolicitacaoSaldo`,
	'consultarAverbacaoPortabilidade': `${_root}/propostas/consultarAverbacaoPortabilidade`,
	'consultarAverbacao': `${_root}/propostas/consultarAverbacao`,
	'assinarPortabilidade': `${_root}/propostas/assinarPortabilidade`,
	'pagarPortabilidade': `${_rootMS}/propostas/pagarPortabilidade`,
	'consultarStatusPortabilidade': `${_rootMS}/propostas/consultarStatusPortabilidade`,
	'assinarRefinPort': `${_root}/propostas/assinarRefinPort`,
	'enviarParaConformidade': `${_root}/propostas/enviarParaConformidade`,

	//-- Autorização
	'buscarAutorizacao': `${_root}/autorizacao/buscarAutorizacao`,
	'autorizar': `${_root}/autorizacao/autorizar`,
	'gerarAutorizacaoInss': `${_root}/autorizacao/gerarAutorizacaoInss`,
	'gerarAutorizacao': `${_root}/autorizacao/gerarAutorizacao`,
	'cancelarAutorizacao': `${_root}/autorizacao/cancelarAutorizacao`,
	'enviarEmailAutorizacao': `${_root}/autorizacao/enviarEmail`,

	//-- Banco Original
	'simulacaoOriginal': `${_root}/original/simulacao`,
	'alterarSimulacaoOriginal': `${_root}/original/alterarSimulacao`,

	//-- Sicoob
	'emailAnaliseRiscoSicoob': `${_root}/sicoob/emailAnaliseRiscoSicoob`,

	//-- FGTS 
	'simulacaoFgts': `${_root}/fgts/simulacao`,
	'gravarPropostaFgts': `${_root}/fgts/gravarPropostaFgts`,

	//-- Outros 
	'listarAuditAssinatura': `${_root}/assinaturas/listarauditoria`,
	'obterSelfie': `${_root}/confia/getselfie`,
	'aprovardocumentos': `${_root}/propostas/checarDocumentos`,
	'dashboardTotais': `${_root}/dashboard/totais`,
	'processarProcredito': `${_root}/propostas/processarProcredito`,
	'reapresentarTED': `${_root}/propostas/reapresentarted`,
	'enviarDadosTED': `${_root}/propostas/enviardadosted`,
	'auditoriaBancaria': `${_root}/propostas/payloadsList`,
	'obterURLAssinatura': `${_root}/confia/getSignUrlEx`,
	'templatesIndex': `${_root}/templates/index`,
	'calcularSaldoDevedor': `${_rootMS}/uteis/calcularSaldoDevedor`,

	'gerarPropostaRefinPort': `${_root}/propostas/gerarPropostaRefinPort`,
	'getPropostasRefinPort': `${_root}/propostas/getPropostasRefinPort`,
	'getPropostaRefinPort': `${_root}/propostas/getPropostaRefinPort`,

	//-- Solicitacoes(Todo módulo de solicitações foi movido para o serviço auxiliar)
	'listarSolicitacoes': `${_rootAux}/solicitacao/index`,
	'gravarSolicitacao': `${_rootAux}/solicitacao/store`,
	'executarSolicitacao': `${_rootAux}/solicitacao/executar`,
	'cancelarSolicitacao': `${_rootAux}/solicitacao/cancelar`,

	//-- Retencoes
	'retencoes': `${_root}/retencao/index`,
	'adiantamentoRetencao': `${_root}/retencao/geraradiantamento`,
	'refinanciamentoRetencao': `${_root}/retencao/gerarrefinanciamento`,
	'gravarRetencao': `${_root}/retencao/store`,
	'cancelarRetencao': `${_root}/retencao/cancel`,
	'estatisticasRetencao': `${_root}/retencao/getEstatisticas`,

	//-- Reapresentação TED
	'reapresentacoes': `${_root}/reapresentacao/index`,
	'gravarReapresentacao': `${_root}/reapresentacao/store`,
	'cancelarReapresentacao': `${_root}/reapresentacao/cancel`,

	//-- MFA
	'setup2fa': `${_root}/mfa/setup2fa`,
	'checarTokenMFA': `${_root}/mfa/verify2fa`,
	'getMfaData': `${_root}/mfa/getmfadata`,
	'cancelar2fa': `${_root}/mfa/cancelar2fa`,

	//-- Inbursa
	'criarUsuarioInbursa': `${_root}/inbursa/criarUsuario`
})[uri] || '/';

export const forms = [
	'simulador',
	'resultado',
	'dados',
	'endereco',
	'contato',
	'complemento',
	'bancarios',
	'documentos',
	'selfie'
];

export const defaultHeaders = (...props) => {
	console.log("defaultHeaders", props)
	let tokenip = getCookie({ label: 'ip' })
	let [latitude, longitude] = getCookie({ label: 'geolocation' }) ? getCookie({ label: 'geolocation' }).split(',') : ['', ''];
	const app_key = criptografar(APP_KEY)
	console.log("config.defaultHeaders(app_key)", app_key)
	return {
		"Content-Type": 'application/json',
		"Accept": '*',
		"key": APP_KEY,
		"Authorization": `bearer ${getItemCrypt(TOKEN_KEY)}`,
		"captcha": getItemCrypt(TOKEN_CAPTCHA),
		tokenip,
		"geolocation": JSON.stringify({ latitude, longitude }),
		"bancoFornecedor": getItemCrypt(TOKEN_BANK, true)
	}
};

export const allViews = [
	'simulador',
	'dados',
	'endereco',
	'contato',
	'complemento',
	'bancarios',
	'selfie',
	'documentos'
];

export const terms = {
	title: "Contrato de empréstimo consignado",
	text: "Termos e condições do empréstimo"
}

export const enviroment = 'prod'; // when in prod enviroment, the forms response wont give any error, even if it has one.
export const TOKEN_KEY = "@inconta-token"
export const TOKEN_USER = "@inconta-user"
export const TOKEN_CAPTCHA = "@inconta-captcha"
export const TOKEN_BANK = "@inconta-bank"
export const APP_KEY = process.env.REACT_APP_KEY

export const versao = {
	number: "1.0.0.1",

}